import React from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../content/assets/raw/loading.json'; // Replace with your animation file path

const Loader = ({ show }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.loaderContainer}>
        <Lottie options={defaultOptions} height={100} width={100} style={{ marginTop: '-50px' }}  />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(35, 51, 68, 0.59)', // Blue with 5% opacity
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000 // Ensure it's on top of everything
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};

export default Loader;
