import React, { useState } from "react";
import "./Withdraw.css";
import Header from "../../../components/Header";
import { Icons } from "../../../content/Icons";

import { useNavigate } from "react-router-dom";

import { sendFCMNotification } from '../../../components/sendnoti';

import axios from 'axios';

import Loader from '../../../components/Loader';


const Withdraw = () => {

  const user = localStorage.getItem('userInfo');
  const parsedUser = JSON.parse(user);

  const [loading, setLoading] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [bankName, setBankName] = useState("");
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

  const [MsgOfAlert, setMsgOfAlert] = useState('');
  const [isAlertMsg, setAlertMsg] = useState(false);



  const [tempAmount, setTempAmount] = useState('');


  const [selectedAccountDigit, setSelectedAccountDigit] = useState();

  const withdrawList = [
    { name: "Easypaisa", pic: Icons.easypaisaIcon },
    { name: "JazzCash", pic: Icons.JazzCash },
    { name: "BankTransfer", pic: Icons.BankTransfer },
  ];

  const navigate = useNavigate();

  const handleSuccessBtnOk = () => {

    setIsPaymentSuccess(false);
    navigate('/dashboard');



}

  const handleAccountChange = (account) => {
    setSelectedAccount(account);
    setAmount("");
    setAccountName("");
    setAccountNumber("");
    setBankName("");

    if (account == "Easypaisa") {
     
      setSelectedAccountDigit(1);
      setBankName('Easypaisa')

  } else if (account == "JazzCash") {
      
      setSelectedAccountDigit(2);
      setBankName('JazzCash')


  }
  else if (account == "BankTransfer") {
     
      setSelectedAccountDigit(3);
      setBankName('');

  }


  };

  const handleDepositConfirmation = async () => {


    setLoading(true);


    if (!(amount >= 500 && amount <= 25000)) {
      setLoading(false);
      // alert('Please write a correct amount to proceed')

      setMsgOfAlert('Please write a correct amount to proceed');
      setAlertMsg(true);
      
    }
    else if (!accountName || !accountNumber) {
      setLoading(false);
      // alert('please enter valid account details.')
     
      setMsgOfAlert('please enter valid account details.');
      setAlertMsg(true);
      
      
    }
    else if (!bankName && selectedAccountDigit === 3) {
      setLoading(false);
      // alert('please enter valid account details.')

      setMsgOfAlert('please enter valid account details.');
      setAlertMsg(true);
      
    }

    else {
   

      if (parsedUser.userActive == 1) {


        await checkPendingWithdraw(parsedUser.userID, 'withdraw',bankName);

      } else {
        setLoading(false);
        // alert('Please activate your account before \n requesting a withdrawal.');

        setMsgOfAlert('Please activate your account before \n requesting a withdrawal.');
        setAlertMsg(true);
        
      }

    }


  };





  // Function to check for pending deposits
  const checkPendingWithdraw = async (userName, tableName, selectedPaymentOption) => {
    try {


      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 20 seconds timeout

      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('user_id', userName);
      params.append('table_name', tableName);

      // Send the POST request to check for pending deposits
      const response = await axios.post(
        'https://api2.betprowallet.pk/api_fetch_pending_deposits.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);





      if (response.data[response.data.length - 1].status == '0') {
       
        setLoading(false);


        // alert('You have a withdraw in pending.');
        
        setMsgOfAlert('You have a withdraw in pending.');
        setAlertMsg(true);



        



      } else {



        submitWithdraw(
          'withdraw',
          parsedUser.userID,
          parsedUser.name,
          '1',
          amount,
          accountName || '',
          accountNumber || '',
          bankName || selectedPaymentOption,
          parsedUser.bp_username, // Image URL after upload
          '0',
          parsedUser.number,
          
        );


      }



    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        // alert('Request timed out. Please try again.');
      } else {
        // alert('Please check your internet connection.');

      }

      setMsgOfAlert('Please check your internet connection.');
      setAlertMsg(true);



    

      console.log(error);
      

    }
  };


  
  // Function to submit deposit details to the server
  const submitWithdraw = async (
    tableName,
    id,
    username,
    accType,
    amount,
    accTitle,
    accNum,
    bankName,
    betProUsername,
    status,
    whatsapp,
  ) => {
    try {
      // Prepare the parameters for the POST request
      const params = new URLSearchParams();
      params.append('username', username);
      params.append('user_id', id);
      params.append('acc_type', accType);
      params.append('amount', amount);
      params.append('to', accTitle);
      params.append('acc', accNum);
      params.append('deposit_bank_name', bankName);
      params.append('bet_pro_username', betProUsername);
      params.append('proof', ''); // Image URL
      params.append('status', status);
      params.append('whatsapp', whatsapp);
      params.append('table_name', tableName);

      // Create a cancel token and timeout
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 30000); // 30 seconds timeout

      // Send the POST request to submit the deposit details
      const response = await axios.post(
        'https://api2.betprowallet.pk/pendingUserInsert.php',
        params,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          cancelToken: source.token,
        }
      );

      // Clear the timeout
      clearTimeout(timeout);

      // Handle the server's response
      if (response.data.status === 'success') {


        await sendFCMNotification('/topics/com.b.adminweb', 'Withdraw', 'Withdraw of Amount ' + amount);
        
        setTempAmount(amount);

        setIsPaymentSuccess(true);


        setSelectedAccount('');
        setAmount("");
        setAccountName("");
        setAccountNumber("");
        setBankName("");

        setLoading(false);










      } else {
        setLoading(false);
        // alert('Failed to submit withdraw. Please try again.');

        setMsgOfAlert('Failed to submit withdraw. Please try again.');
        setAlertMsg(true);


      }

      
     
    } catch (error) {
      setLoading(false);
      if (axios.isCancel(error)) {
        // alert('Withdraw submission timed out. Please try again.');
      } else {
        // alert('Failed to submit withdraw. Please check your internet connection.');
      }


      setMsgOfAlert('Please check your internet connection.');
      setAlertMsg(true);


      
     
    }
  };



  
  const handleSuccessBtnOk2 = () => {

    setAlertMsg(false);


}







  return (
    <div>
      <Header title="Withdraw" />
      <div className="withdraw-area p-4 max-w-md mx-auto">
        <div className="withdraw-items mb-10">
          <h1>Select Account</h1>
          {withdrawList.map((items, index) => (
            <button
              key={index}
              type="button"
              className="w-full block items-center mt-3 mb-3"
              onClick={() => handleAccountChange(items.name)}
            >
              <div className="flex items-center justify-between w-full h-full">
                <div className="flex items-center">
                  <img src={items.pic} alt={items.name} />
                  <p>{items.name}</p>
                </div>
                <label className="custom-checkbox">
                  <input
                    type="checkbox"
                    checked={selectedAccount === items.name}
                    readOnly
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </button>
          ))}
        </div>

        {selectedAccount && (
          <div className="withdraw-amount">
            <p lassName="form-group" style={{marginRight:"10px",marginBottom:"10px",fontSize:"10px",textAlign:"end"}}>Amount Min Rs.500 - max 25000  </p>
            <div className="form-group flex items-center amount-box mb-5">
              <div className="w-[200px]">
                <label htmlFor="enterAmount">Enter amount</label>
              </div>
              <div className=" relative">
                <input
                  type="number"
                  id="enterAmount"
                  className="form-control"
                  placeholder="Enter Amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
            </div>

            <div className="form-group flex items-center amount-box mb-5">
              <div className="w-[200px]">
                <label htmlFor="enterAccountName">Enter account name:</label>
              </div>
              <div className=" relative">
                <input
                  type="text"
                  id="enterAccountName"
                  className="form-control"
                  value={accountName}
                  onChange={(e) => setAccountName(e.target.value)}
                  placeholder="your account name"
                />
              </div>
            </div>

            <div className="form-group flex items-center amount-box mb-5">
              <div className="w-[250px]">
                <label htmlFor="enterAccountNumber">
                  Enter account number:
                </label>
              </div>
              <div className="relative">
                <input
                  type="number"
                  id="enterAccountNumber"
                  className="form-control"
                  value={accountNumber}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  placeholder="your account number"
                />

              </div>
            </div>

            {selectedAccount === "BankTransfer" && (
              <div className="form-group flex items-center amount-box mb-5">
                <div className="w-[250px]">
                  <label htmlFor="enterBankName">Enter bank name:</label>
                </div>
                <input
                  type="text"
                  id="enterBankName"
                  className="form-control"
                  value={bankName}
                  onChange={(e) => setBankName(e.target.value)}
                  placeholder="Enter bank name"
                />
              </div>
            )}
          </div>
        )}

        <div className="confirm-withdraw w-full my-10">
          <button type="button" onClick={handleDepositConfirmation}>
            Confirm Withdraw
          </button>
        </div>

        {isPaymentSuccess && (
          <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
            <div className="withdraw-modal">
              <div className="payment-success flex flex-col justify-between h-full items-center">
                <img src={Icons.SuccessIcon} alt="SuccessIcon" />
                <h2>Withdraw Success!</h2>
                <p>Rs. {tempAmount}</p>

                <button onClick={handleSuccessBtnOk}>OK</button>
              </div>
            </div>
          </div>
        )}
      </div>

      {isAlertMsg && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="modal">
                            <div className="payment-success flex flex-col justify-between h-full items-center">
                                <img
                                    src={Icons.withdraw}
                                    alt="SuccessIcon"
                                    style={{
                                        filter: 'brightness(0) saturate(100%) invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(0) contrast(100%)',
                                        
                                    }}
                                />
                                <h3 style={{ margin: "0px 10px" , textAlign : "center"}}>{MsgOfAlert}</h3>
                                <button onClick={handleSuccessBtnOk2}>OK</button>
                            </div>
                        </div>
                    </div>
                )}


      <Loader show={loading} />

    </div>
  );
};

export default Withdraw;
