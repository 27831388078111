// icons
import userIcon from "./assets/icons/user.svg"
import lockIcon from "./assets/icons/lock.svg"
import eyeIcon from "./assets/icons/eye.svg"
import eyeSlashIcon from "./assets/icons/eyeSlash.svg"
import mailIcon from "./assets/icons/mail.svg"
import forgotPasswordIcon from "./assets/icons/forgotPassword.svg"
import broIcon from "./assets/icons/bpro.svg"
import depositIcon from "./assets/icons/deposit.svg"
import mainIcon from "./assets/icons/main.svg"
import menuIcon from "./assets/icons/menu.svg"
import historyIcon from "./assets/icons/history.svg"
import icon1 from "./assets/icons/icon1.svg"
import icon2 from "./assets/icons/icon2.svg"
import icon3 from "./assets/icons/icon3.svg"
import logos_whatsapp from "./assets/icons/logos_whatsapp.svg"
import copyIcon from "./assets/icons/copy.svg"
import fileIcon from "./assets/icons/file.svg"
import easypaisaIcon from "./assets/icons/easypaisa.svg"
import bankIcon from "./assets/icons/bank.svg"
import jazzCashIcon from "./assets/icons/JazzCash.svg"
import SuccessIcon from "./assets/icons/SuccessIcon.svg"
import logoutIcon from "./assets/icons/logout.svg"
import signOutIcon from "./assets/icons/signOut.svg"
import BankTransfer from "./assets/icons/bank.svg"
import JazzCash from "./assets/icons/JazzCash.svg"
import historyFile from "./assets/icons/historyFile.svg"
import successCheck from "./assets/icons/successCheck.svg"
import copyIcon2 from "./assets/icons/copyIcon.svg"
import alertMsg from "./assets/icons/alertMsg.svg"
import copyclip from "./assets/icons/copyclip.svg"
import withdraw from "./assets/icons/withdraw.svg"

// active icons 
import activeMenuIcon from "./assets/active/menu.svg"
import activeMainIcon from "./assets/active/main.svg"
import activeBproIcon from "./assets/active/bpro.svg"
import activeHistoryIcon from "./assets/active/history.svg"
export const Icons ={
    userIcon,
    lockIcon,
    eyeIcon,
    eyeSlashIcon,
    mailIcon,
    forgotPasswordIcon,
    broIcon,
    depositIcon,
    mainIcon,
    menuIcon,
    historyIcon,
    icon1,
    icon2,
    icon3,
    logos_whatsapp,
    copyIcon,
    fileIcon,
    easypaisaIcon,
    bankIcon,
    jazzCashIcon,
    SuccessIcon,
    logoutIcon,
    signOutIcon,
    BankTransfer,
    JazzCash,
    historyFile,
    successCheck,
    copyIcon2,
    activeMenuIcon,
    activeMainIcon,
    activeBproIcon,
    activeHistoryIcon,
    alertMsg,
    copyclip,
    withdraw,
    
}