import React, { useState } from 'react';
import "./Deposit.css";
import Header from '../../../components/Header';
import { Icons } from '../../../content/Icons';
import { useNavigate } from "react-router-dom";

import { sendFCMNotification } from '../../../components/sendnoti';

import axios from 'axios';

import Loader from '../../../components/Loader';

const Deposit = () => {

    const user = localStorage.getItem('userInfo');
    const parsedUser = JSON.parse(user);

    const [loading, setLoading] = useState(false);


    const [selectedAccount, setSelectedAccount] = useState('');

    const [selectedAccountAvailable, setSelectedAccountAvailable] = useState(false);

    const [selectedAccountDigit, setSelectedAccountDigit] = useState();

    const [selectedImageUrl, setImageUrl] = useState('');

    const [MsgOfAlert, setMsgOfAlert] = useState('');
    const [isAlertMsg, setAlertMsg] = useState(false);


    const [amount, setAmount] = useState('');

    const navigate = useNavigate();

    const [tempAmount, setTempAmount] = useState('');

    const [file, setFile] = useState(null);
    const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);

    const [accountDetails, setAccountDetails] = useState({
        accountId: 0,
        accountNo: '',
        accountName: '',
    });

    const handleAccountChange = (account) => {
        setSelectedAccount(account);

        if (account == "Easypaisa") {
            accountFetch(1); // Fetch account details based on the selected payment method
            setSelectedAccountDigit(1);

        } else if (account == "JazzCash") {
            accountFetch(2); // Fetch account details based on the selected payment method
            setSelectedAccountDigit(2);

        }
        else if (account == "BankTransfer") {
            accountFetch(3); // Fetch account details based on the selected payment method
            setSelectedAccountDigit(3);

        }
    };


    const handleSuccessBtnOk = () => {

        setIsPaymentSuccess(false);
        navigate('/dashboard');



    }



    const accountFetch = async (paymentId) => {
        const params = new URLSearchParams();
        params.append('account_type', paymentId);
        params.append('table_name', "accounts");

        try {
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 20000); // 20 seconds timeout

            const response = await axios.post('https://api2.betprowallet.pk/api_fetch_accounts.php', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                cancelToken: source.token,
            });

            clearTimeout(timeout);

            // Handle the response and update state
            if (response.data && response.data.length > 0) {
                const account = response.data[0]; // Assuming the first item is what you need

                if (account.account_active == 1) {

                    setAccountDetails({

                        accountId: selectedAccountDigit,
                        accountNo: account.account_number,
                        accountName: account.account_title,
                        bankName: account.account_bank_name,

                    });

                    setSelectedAccountAvailable(true);

                } else {



                    setAccountDetails({
                        accountNo: '',
                        accountName: '',
                        bankName: '',

                    });

                    setSelectedAccountDigit(null);
                    setSelectedAccount('');

                    setSelectedAccountAvailable(false);

                    // alert('Account Not Availble');

                    setMsgOfAlert('Account Not Availble');
                    setAlertMsg(true);


                }


            } else {
                setAccountDetails({
                    accountNo: '',
                    accountName: '',
                    bankName: '',
                });
            }
        } catch (error) {




            setAccountDetails({
                accountNo: '',
                accountName: '',
                bankName: '',
            });

            setSelectedAccountDigit(null);
            setSelectedAccount('');



        }
    };




    const handleDepositConfirmation = async () => {

        setLoading(true);

        if (!selectedAccount) {
            setLoading(false);
            // alert('Please select a Payment Option to proceed');


            setMsgOfAlert('Please select a Payment Option to proceed');
            setAlertMsg(true);



        } else if (!file) {
            setLoading(false);
            // alert('Please upload image to proceed');

            setMsgOfAlert('Please upload image to proceed');
            setAlertMsg(true);

        } else if (!(amount >= 500)) {
            setLoading(false);
            // alert('Please write a correct amount to proceed');

            setMsgOfAlert('Please write a correct amount to proceed');
            setAlertMsg(true);

        } else if (accountDetails.account_number == '') {
            setLoading(false);
            // alert('Please select a Payment Option to proceed');

            setMsgOfAlert('Please select a Payment Option to proceed');
            setAlertMsg(true);


        }
        else {
            // alert('Deposit request created successfully');

            // 
            // Call the function to check for pending deposits
            await checkPendingDeposits(parsedUser.userID, 'pendingusers');



            // console.log(paymentOptions[selectedPayment].text);


        }
    };







    // Function to check for pending deposits
    const checkPendingDeposits = async (userName, tableName) => {
        try {


            // Create a cancel token and timeout
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 30000); // 20 seconds timeout

            // Prepare the parameters for the POST request
            const params = new URLSearchParams();
            params.append('user_id', userName);
            params.append('table_name', tableName);

            // Send the POST request to check for pending deposits
            const response = await axios.post(
                'https://api2.betprowallet.pk/api_fetch_pending_deposits.php',
                params,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    cancelToken: source.token,
                }
            );

            // Clear the timeout
            clearTimeout(timeout);




            // Handle the server's response
            // if (response.data.message === '0 results') {
            //   alert('No pending deposits found. Proceeding with the deposit request.');
            //   // You can call the next step of processing the deposit here
            //   await checkPendingDeposits(parsedUser.userID,'pendingusers', setMsg, handleShow);


            // } 



            if (response.data[response.data.length - 1].status == '0') {

                setAccountDetails({
                    accountNo: '',
                    accountName: '',
                    bankName: '',
                });

                setAmount(null);
                setFile(null);


                setSelectedAccountDigit(null);
                setSelectedAccount('');
                setLoading(false);

                // alert('You already have a pending deposit.');

                

                setMsgOfAlert('You already have a pending deposit.');
                setAlertMsg(true);
    


            } else {




                await checkDeposits(parsedUser.userID, 'deposit');


            }








        } catch (error) {
            setLoading(false);
            if (axios.isCancel(error)) {
                // alert('Request timed out. Please try again.');
            } else {
                // alert('Please check your internet connection.');
            }
            setMsgOfAlert('Please check your internet connection.');
            setAlertMsg(true);

        }
    };



    // Function to check for pending deposits
    const checkDeposits = async (userName, tableName) => {
        try {


            // Create a cancel token and timeout
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 30000); // 20 seconds timeout

            // Prepare the parameters for the POST request
            const params = new URLSearchParams();
            params.append('user_id', userName);
            params.append('table_name', tableName);

            // Send the POST request to check for pending deposits
            const response = await axios.post(
                'https://api2.betprowallet.pk/api_fetch_pending_deposits.php',
                params,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    cancelToken: source.token,
                }
            );

            // Clear the timeout
            clearTimeout(timeout);




            // Handle the server's response
            // if (response.data.message === '0 results') {
            //   alert('No pending deposits found. Proceeding with the deposit request.');
            //   // You can call the next step of processing the deposit here
            //   await checkPendingDeposits(parsedUser.userID,'pendingusers', setMsg, handleShow);


            // } 



            if (response.data[response.data.length - 1].status == '0') {

                setAccountDetails({
                    accountNo: '',
                    accountName: '',
                    bankName: '',
                });

                setAmount(null);
                setFile(null);


                setSelectedAccountDigit(null);
                setSelectedAccount('');
                setLoading(false);

                // alert('You already have a pending deposit.');

                setMsgOfAlert('You already have a pending deposit.');
                        setAlertMsg(true);




            } else {



                // You can call the next step of processing the deposit here


                uploadImageTo(file, setImageUrl);







            }








        } catch (error) {
            setLoading(false);
            if (axios.isCancel(error)) {
                // alert('Request timed out. Please try again.');
                setMsgOfAlert('Request timed out. Please try again.');
                        setAlertMsg(true);
            } else {
                // alert('Please check your internet connection.');

                setMsgOfAlert('Please check your internet connection.');
                        setAlertMsg(true);
            }


        }
    };


    // Function to upload the deposit image
    const uploadImageTo = async (imageFile, setImageUrl) => {
        try {
            // Read the image file and convert it to Base64
            const reader = new FileReader();

            reader.onloadend = async () => {
                const base64Image = reader.result.split(',')[1]; // Extract Base64 part of the image

                // Prepare the parameters for the POST request
                const params = new URLSearchParams();
                params.append('image', base64Image);

                // Create a cancel token
                const source = axios.CancelToken.source();

                // Start the request
                try {
                    const response = await axios.post(
                        'https://api2.betprowallet.pk/upload_image_to_storage.php',
                        params,
                        {
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded',
                            },
                            cancelToken: source.token,
                            timeout: 60000, // Timeout of 30 seconds for the request
                        }
                    );

                    // Handle the server's response
                    const jsonResponse = response.data;

                    if (jsonResponse.status === 'success') {
                        const imageUrl = jsonResponse.imageUrl;
                        setImageUrl(imageUrl); // Save the image URL




                        if (parsedUser.userActive == 1) {

                            submitDeposit(
                                'deposit',
                                parsedUser.userID,
                                parsedUser.name,
                                '1',
                                amount,
                                accountDetails.accountName,
                                accountDetails.accountNo,
                                accountDetails.bankName,
                                parsedUser.bp_username,
                                imageUrl, // Image URL after upload
                                '0',
                                parsedUser.number,

                            );

                        } else {

                            submitDeposit(
                                'pendingusers',
                                parsedUser.userID,
                                parsedUser.name,
                                '1',
                                amount,
                                accountDetails.accountName,
                                accountDetails.accountNo,
                                accountDetails.bankName,
                                parsedUser.bp_username,
                                imageUrl, // Image URL after upload
                                '0',
                                parsedUser.number,

                            );

                        }


                    } else {
                        setLoading(false);
                        // alert('Image upload failed.');

                        setMsgOfAlert('Image upload failed.');
                        setAlertMsg(true);

                    }
                } catch (error) {
                    setLoading(false);
                    if (axios.isCancel(error)) {
                        // alert('Image upload was canceled due to timeout.');

                        setMsgOfAlert('Image upload was canceled due to timeout.');
                        setAlertMsg(true);

                    } else if (error.code === 'ECONNABORTED') {
                        // alert('Image upload request took too long. Please try again.');

                        setMsgOfAlert('Image upload request took too long. Please try again.');
                        setAlertMsg(true);
                    } else {
                        // alert('Image upload failed. Please check your internet connection.');


                        setMsgOfAlert('Image upload failed. Please check your internet connection.');
                        setAlertMsg(true);

                    }


                }
            };

            // Handle file reading error
            reader.onerror = () => {
                setLoading(false);
                // alert('Error reading image file. Please try again.');

                setMsgOfAlert('Error reading image file. Please try again.');
                setAlertMsg(true);

            };

            // Start reading the image file as Base64
            reader.readAsDataURL(imageFile);
        } catch (error) {
            setLoading(false);
            // alert('An unexpected error occurred. Please try again.');

          

        }
    };


    // Function to submit deposit details to the server
    const submitDeposit = async (
        tableName,
        id,
        username,
        accType,
        amount,
        accTitle,
        accNum,
        bankName,
        betProUsername,
        downloadUrl,
        status,
        whatsapp,

    ) => {
        try {
            // Prepare the parameters for the POST request
            const params = new URLSearchParams();
            params.append('username', username);
            params.append('user_id', id);
            params.append('acc_type', accType);
            params.append('amount', amount);
            params.append('to', accTitle);
            params.append('acc', accNum);
            params.append('deposit_bank_name', bankName);
            params.append('bet_pro_username', betProUsername);
            params.append('proof', downloadUrl); // Image URL
            params.append('status', status);
            params.append('whatsapp', whatsapp);
            params.append('table_name', tableName);

            // Create a cancel token and timeout
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 30000); // 30 seconds timeout

            // Send the POST request to submit the deposit details
            const response = await axios.post(
                'https://api2.betprowallet.pk/pendingUserInsert.php',
                params,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    cancelToken: source.token,
                }
            );

            // Clear the timeout
            clearTimeout(timeout);

            // Handle the server's response
            if (response.data.status === 'success') {



                if (parsedUser.userActive == 0) {

                    await sendFCMNotification('/topics/com.b.adminweb', 'Deposit', 'Deposit of Amount ' + amount + ' For User Activation');



                } else {

                    await sendFCMNotification('/topics/com.b.adminweb', 'Deposit', 'Deposit of Amount ' + amount);

                }



                setIsPaymentSuccess(true);

                setTempAmount(amount);




                setAccountDetails({
                    accountNo: '',
                    accountName: '',
                    bankName: '',
                });

                setAmount(null);
                setFile(null);


                setSelectedAccountDigit(null);
                setSelectedAccount('');
                setLoading(false);

            } else {
                setLoading(false);
                // alert('Failed to submit deposit. Please try again.');
                setMsgOfAlert('Failed to submit deposit. Please try again.');
                setAlertMsg(true);

            }



        } catch (error) {
            setLoading(false);
            if (axios.isCancel(error)) {
                // alert('Deposit submission timed out. Please try again.');
            } else {
                // alert('Failed to submit deposit. Please check your internet connection.');
            }

            setMsgOfAlert('Please check your internet connection.');
            setAlertMsg(true);




        }
    };


    const copyToClipboard = () => {
        navigator.clipboard.writeText(accountDetails.accountNo)
            .then(() => {

                // alert("Account Number copied to clipboard!");

                setMsgOfAlert('Account Number copied to clipboard!');
                setAlertMsg(true);

            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };





    const handleSuccessBtnOk2 = () => {

        setAlertMsg(false);


    }






    return (
        <div>
            <Header title="Deposit" />
            <div className="deposit-area p-4 max-w-md mx-auto">
                <div className="deposit-items mb-10">
                    <h1>Select Account</h1>
                    <button type="button" className='w-full block items-center mt-3 mb-3' onClick={() => handleAccountChange('Easypaisa')}>
                        <div className="flex items-center justify-between w-full h-full">
                            <div className="flex items-center">
                                <img src={Icons.easypaisaIcon} alt="easypaisaIcon" />
                                <p>Easypaisa</p>
                            </div>
                            <label className="custom-checkbox">
                                <input type="checkbox" checked={selectedAccount === 'Easypaisa'} readOnly />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </button>
                    <button type="button" className='w-full block items-center mb-3' onClick={() => handleAccountChange('JazzCash')}>
                        <div className="flex items-center justify-between w-full h-full">
                            <div className="flex items-center">
                                <img src={Icons.jazzCashIcon} alt="jazzCash" />
                                <p>JazzCash</p>
                            </div>
                            <label className="custom-checkbox">
                                <input type="checkbox" checked={selectedAccount === 'JazzCash'} readOnly />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </button>
                    <button type="button" className='w-full block items-center mb-3' onClick={() => handleAccountChange('BankTransfer')}>
                        <div className="flex items-center justify-between w-full h-full">
                            <div className="flex items-center">
                                <img src={Icons.bankIcon} alt="bankIcon" />
                                <p>Bank Transfer</p>
                            </div>
                            <label className="custom-checkbox">
                                <input type="checkbox" checked={selectedAccount === 'BankTransfer'} readOnly />
                                <span className="checkmark"></span>
                            </label>
                        </div>
                    </button>
                </div>

                {selectedAccountAvailable && (
                    <div className="deposit-amount">
                        <p lassName="form-group" style={{ marginRight: "10px", marginBottom: "10px", fontSize: "10px", textAlign: "end" }}>Minimum Amount Rs.500 </p>
                        <div className="form-group flex items-center amount-box">
                            <div className='w-[200px]'>
                                <label htmlFor="enterAmount">Enter amount</label>
                            </div>

                            <input
                                type="number"
                                id="enterAmount"
                                className="form-control"
                                placeholder="Enter Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}

                            />
                        </div>
                        <div className="flex flex-wrap account-details my-5">
                            <div className="wallet-name w-full">
                                <h1>{selectedAccount}</h1>
                            </div>
                            <div className="flex flex-wrap w-full items-center h-full">
                                <div className="w-8/12">
                                    <div className="content">
                                        <h3>Account No: {accountDetails.accountNo}</h3>
                                        <h3>Account Name: {accountDetails.accountName}</h3>
                                        {selectedAccount === 'BankTransfer' && (
                                            <h3>Bank Name: {accountDetails.bankName}</h3>
                                        )}
                                    </div>
                                </div>
                                <div className="w-4/12 flex justify-center items-center h-full">
                                    <div className="copy-button">
                                        <button type="button" onClick={copyToClipboard}>
                                            <div className="flex flex-col justify-center items-center">
                                                <img src={Icons.copyIcon} alt="copyIcon" />
                                                <p>Copy</p>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="form-group flex items-center payment-proof justify-between h-full py-3">
                            <div className='w-[200px] overflow-hidden h-full flex items-center'>
                                <label htmlFor="fileUpload">Send payment proof:</label>
                            </div>
                            <div className="deposit-file-upload">
                                <label className="w-full">
                                    <input type="file" className="hidden" onChange={(e) => setFile(e.target.files[0])} />
                                    <div className="flex items-center justify-between w-full">


                                        {file ? (
                                            <img
                                                src={URL.createObjectURL(file)}
                                                alt="Betpro Deposit icon"
                                                style={{ width: "20px", height: "20px" }}
                                            />
                                        ) : (
                                            <img src={Icons.fileIcon} alt="Betpro Deposit icon" />
                                        )}

                                        <p>Choose file</p>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                )}

                <div className="confirm-deposit w-full my-10">
                    <button type="button" onClick={handleDepositConfirmation}>Confirm deposit</button>
                </div>


                {isPaymentSuccess && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="modal">
                            <div className="payment-success flex flex-col justify-between h-full items-center">
                                <img src={Icons.SuccessIcon} alt="SuccessIcon" />
                                <h2>Payment Success!</h2>
                                <p>Rs. {tempAmount}</p>

                                <button onClick={handleSuccessBtnOk}>OK</button>
                            </div>
                        </div>
                    </div>
                )}


                {isAlertMsg && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="modal">
                            <div className="payment-success flex flex-col justify-between h-full items-center">
                                <img
                                    src={Icons.depositIcon}
                                    alt="SuccessIcon"
                                    style={{
                                        filter: 'brightness(0) saturate(100%) invert(0) sepia(0) saturate(0) hue-rotate(0deg) brightness(0) contrast(100%)',
                                        
                                    }}
                                />
                                <h3 style={{ margin: "0px 10px" , textAlign : "center"}}>{MsgOfAlert}</h3>
                                <button onClick={handleSuccessBtnOk2}>OK</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <Loader show={loading} />

        </div>
    );
};

export default Deposit;
