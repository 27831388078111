// src/pages/auth/register/Register.js
import { useFormik } from "formik";
import * as Yup from "yup";
import "./register.css";
import { Icons } from "../../../content/Icons";
import { useState } from "react";
import Header from "../../../components/Header";
import { useAuth } from "../../../context/AuthContext";

const Register = () => {
    const [showPassword, setShowPassword] = useState(false);
    const { register } = useAuth();
    const [loading, setLoading] = useState(false);

    const [isGotoLogin, setIsGoToLogin] = useState(false);

    const [MsgOfAlert , setMsgOfAlert]  = useState('');
  const [isAlertMsg , setAlertMsg] = useState(false);


    // Validation schema using Yup
    const validationSchema = Yup.object({
        Name: Yup.string().required("Please enter your full name"),
        Number: Yup.string().required("Please provide a correct phone number"),
        emailOrPhone: Yup.string()
            .required("Please provide a correct email")
            .email("Invalid email format"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .required("Password is required"),
    });

    // Formik setup
    const formik = useFormik({
        initialValues: {
            Name: "",
            Number: "",
            emailOrPhone: "",
            password: "",
        },
        validationSchema,
        onSubmit: (values) => {
            setLoading(true)
            register(values,setLoading,setMsgOfAlert,setAlertMsg); // Call register function from AuthContext
        },
    });

    // Toggle password visibility
    const handleClick = () => {
        setShowPassword(!showPassword);
    };

    const handleSuccessBtnOk = () => {

        setAlertMsg(false);
      
    
    }

    
    return (
        <div className="register-area">
            <div className="bg-[#FFFFFF]">
                <Header title="Betpro Wallet" />
                <div className="w-full max-w-md flex flex-col items-center justify-center h-[80vh] relative mx-auto">
                    <div className="w-full px-3">
                        {/* Welcome Text */}
                        <div className="mt-6">
                            <h2 className="px-3 font-['Inter',sans-serif] text-[16px] font-[700] text-[#27312C]">
                                Sign up
                            </h2>
                        </div>

                        {/* Register Form */}
                        <form
                            onSubmit={formik.handleSubmit}
                            className="register-form mt-4 w-full"
                        >
                            <div>
                                {/* Name Input */}
                                <div className="form-group">
                                    <div className="relative">
                                        <input
                                            className={`form-control ${formik.touched.Name && formik.errors.Name
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                }`}
                                            placeholder="Name"
                                            type="text"
                                            name="Name"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Name}
                                        />
                                    </div>
                                </div>
                                <div className="divider"></div>

                                {/* Number Input */}
                                <div className="form-group">
                                    <div className="relative">
                                        <input
                                            className={`form-control ${formik.touched.Number && formik.errors.Number
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                }`}
                                            placeholder="Number"
                                            type="number"
                                            name="Number"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.Number}
                                        />
                                    </div>
                                </div>
                                <div className="divider"></div>

                                {/* Email Input */}
                                <div className="form-group">
                                    <div className="relative">
                                        <input
                                            className={`form-control ${formik.touched.emailOrPhone && formik.errors.emailOrPhone
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                }`}
                                            placeholder="Email"
                                            type="email"
                                            name="emailOrPhone"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.emailOrPhone}
                                        />
                                    </div>
                                </div>
                                <div className="divider"></div>

                                {/* Password Input */}
                                <div className="form-group">
                                    <div className="relative">
                                        <input
                                            className={`form-control ${formik.touched.password && formik.errors.password
                                                ? "border-red-500"
                                                : "border-gray-300"
                                                }`}
                                            placeholder="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            name="password"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.password}
                                        />
                                        <div className="absolute right-0 flex items-center h-full top-0 px-[10px] z-10">
                                            <button
                                                type="button"
                                                className="password-btn cursor-pointer"
                                                onClick={handleClick}
                                            >
                                                <img
                                                    src={showPassword ? Icons.eyeSlashIcon : Icons.eyeIcon}
                                                    alt="eyeIcon"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="divider"></div>

                                {/* Display validation errors */}
                                <div className="px-2 py-2">
                                    {formik.touched.Name && formik.errors.Name ? (
                                        <p className="invalid-txt">
                                            {formik.errors.Name}
                                        </p>
                                    ) : null}

                                    {formik.touched.emailOrPhone && formik.errors.emailOrPhone ? (
                                        <p className="invalid-txt">
                                            {formik.errors.emailOrPhone}
                                        </p>
                                    ) : null}

                                    {formik.touched.Number && formik.errors.Number ? (
                                        <p className="invalid-txt">
                                            {formik.errors.Number}
                                        </p>
                                    ) : null}

                                    {formik.touched.password && formik.errors.password ? (
                                        <p className="invalid-txt">
                                            {formik.errors.password}
                                        </p>
                                    ) : null}
                                </div>

                                {/* Buttons */}
                                <div className="flex justify-between mt-4 space-x-0">
                                    <div className="w-1/2 p-0 m-0">
                                        <button
                                            type="button"
                                            className="login-btn w-full"
                                            onClick={() => setIsGoToLogin(true) }
                                        >
                                            Login
                                        </button>
                                    </div>
                                    <div className="w-1/2 p-0 m-0">
                                        <button className="register-btn w-full" type="submit" disabled={loading}>
                                        {loading ? 'Loading...' : 'SignUp'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {isGotoLogin && (
    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center bg-black bg-opacity-50" style={{ zIndex: 1000 }}>
        <div className="modal bg-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-between" style={{ width: '300px', height: '160px' }}>
            <h3 className="text-center text-lg font-semibold mb-3">Are you sure you want to leave this page?</h3>
            <div className="flex justify-between w-full mt-2">
                <button 
                    className="w-1/2 mr-2 px-3 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    style={{background:"#3f5248"}}
                    onClick={() => window.location.href = "/login"}
                >
                    OK
                </button>
                <button 
                    className="w-1/2 ml-2 px-3 py-2 bg-gray-300 text-black rounded hover:bg-gray-400"
                    onClick={() => setIsGoToLogin(false)}
                >
                    Cancel
                </button>
            </div>
        </div>
    </div>
)}

{isAlertMsg && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="modal">
                            <div className="payment-success flex flex-col justify-between h-full items-center">
                                <img src={Icons.alertMsg} alt="SuccessIcon" />
                                <h3 style={{ margin: "0px 10px" , textAlign : "center"}}>{MsgOfAlert}</h3>
                                

                                <button onClick={handleSuccessBtnOk}>OK</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Register;
