// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const AuthContext = createContext();

// Function to generate a token (simple example)
const generateToken = () => {
    return Math.random().toString(36).substr(2) + Math.random().toString(36).substr(2);
};

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(null);
    const [userInfo, setUserInfo] = useState(null);
    const navigate = useNavigate();

    // Load token and user info from localStorage when the app initializes
    useEffect(() => {
        const token = localStorage.getItem("authToken");
        const storedUserInfo = localStorage.getItem("userInfo");
        
        if (token) {
            setAuthToken(token);
        }
        if (storedUserInfo) {
            setUserInfo(JSON.parse(storedUserInfo));
        }
    }, []);


      // Function to check if user exists
  const checkUserExists = async (emailOrPhone, whatsapp) => {
    const params = new URLSearchParams();
    params.append('username', emailOrPhone);
    params.append('user_whatsapp', whatsapp);
    params.append('table_name', 'users');
    params.append('fieldName', 'user_email'); // Use 'user_email' as the field to check

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api2.betprowallet.pk/app_user_check.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      // Check the response from the API
      if (response.data === 'type1 found') {
        return { exists: true, type: 'email' };
      } else if (response.data === 'type2 found') {
        return { exists: true, type: 'whatsapp' };
      } else {
        return { exists: false };
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        // alert('Request timed out. Please try again.');
      } else {
        // alert('Error checking user. Please check your internet connection...');
      }

      return { exists: false }; // Return false in case of any error
    }
  };


    // Function to register a user
    const register =async (values,setLoading ,setMsgOfAlert,setAlertMsg) => {
        // Generate a token
        const token = generateToken();

          // Check if the user exists before proceeding with registration
    const checkResult = await checkUserExists(values.emailOrPhone, values.Number);

    if (checkResult.exists) {
      // Notify the user if they already exist
      // alert(`User with ${checkResult.type} already exists.`);
      setLoading(false);

      setMsgOfAlert(`User with ${checkResult.type} already exists.`);
      setAlertMsg(true);

      return;
    }



        const params = new URLSearchParams();
        params.append('user_email', values.emailOrPhone);
        params.append('user_whatsapp', values.Number);
        params.append('user_fullname', values.Name);
        params.append('user_real_pass', values.password);
        params.append('table_name', 'users');
    
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 20000); // 20 seconds timeout
    
          const response = await axios.post('https://api2.betprowallet.pk/createNewAccUserFromServer.php', params, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: source.token,
          });
    
          clearTimeout(timeout);
    
          console.log('Response data:', response.data);
    
          if (response.data.status === 'success') {
          
          
            localStorage.removeItem('user');
    
            const user = {
              name: response.data.data[0]?.user_fullname || 'User',
              bp_username: response.data.data[0]?.user_betpro_username || '',
              bp_pass: response.data.data[0]?.user_betpro_password || '',
              email: response.data.data[0]?.user_email || '',
              password: '',
              number: response.data.data[0]?.user_whatsapp || 'N/A',
              userID: response.data.data[0]?.user_id || 'N/A',
              userActive: response.data.data[0]?.user_active || 0,
          };
    
        
    
        
          localStorage.setItem("userInfo", JSON.stringify(user));
          localStorage.setItem("authToken", token);
  
          // Set the token and user info in state
          setAuthToken(token);
          setUserInfo(user);
          setLoading(false);
  
          // Redirect to the dashboard
          navigate("/dashboard");
    
          
    
          } else {
            setLoading(false);
            // alert();

            setMsgOfAlert(response.data.message || 'Sign up failed. Please try again.');
            setAlertMsg(true);

          
          }
        } catch (error) {
          if (axios.isCancel(error)) {
            // alert('Request timed out. Please try again.');
          } else {
            // alert('Please check your internet connection...');
          }

          setLoading(false);

          setMsgOfAlert('Please check your internet...');
          setAlertMsg(true);
          
        } finally {
         
        }




    };


     // Function to register a user
     const login = async (values,setLoading,setMsgOfAlert,setAlertMsg) => {
       
        const token = generateToken();

    

       const emailOrPhone =  values.emailOrPhone;
       const password = values.password;


        const userField = emailOrPhone.length === 11 && !emailOrPhone.includes('@') ? 'user_number' : 'user_email';

        const params = new URLSearchParams();
        params.append('username', emailOrPhone);
        params.append('real_pass', password);
        params.append('fieldName', userField);
        params.append('table_name', 'users');

        try {
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel();
            }, 20000); // 20 seconds timeout

            const response = await axios.post('https://api2.betprowallet.pk/loginUserApi_ft.php', params, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                cancelToken: source.token,
            });

            clearTimeout(timeout);


            


            if (response.data.message === '0 results') {
                setLoading(false);
               
            } else {




                // Check if response starts with "0 results"
                if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
                    setLoading(false);
                    // alert('User not found. Please try again.');
                   
                    setMsgOfAlert('User not found. Please try again.');
                    setAlertMsg(true);

                    // navigate("/");
                    
                   
                } else {



                    if (response.data[0].user_blocked === '0') {

                        localStorage.removeItem('user');

                        const user = {
                            name: response.data[0]?.user_fullname || 'User',
                            bp_username: response.data[0]?.user_betpro_username || '',
                            bp_pass: response.data[0]?.user_betpro_password || '',
                            email: response.data[0]?.user_email || '',
                            password: '',
                            number: response.data[0]?.user_whatsapp || 'N/A',
                            userID: response.data[0]?.user_id || 'N/A',
                            userActive: response.data[0]?.user_active || 0,
                        };




      

        localStorage.setItem("userInfo", JSON.stringify(user));
        localStorage.setItem("authToken", token);

        // Set the token and user info in state
        setAuthToken(token);
        setUserInfo(user);
        setLoading(false);

        // Redirect to the dashboard
        navigate("/dashboard");

       

                    } else {
                        setLoading(false);
                        // alert('User not found. Your email, phone number or password is incorrect.');    
                        
                        setMsgOfAlert('User not found. Your email, phone number or password is incorrect.');
                        setAlertMsg(true);
    

                    }




                }







            }

        } catch (error) {
            if (axios.isCancel(error)) {


               
            } else {
               
            }
            setLoading(false);

            setMsgOfAlert('Please check your internet...');
            setAlertMsg(true);



     
         

            
        } finally {
           
        }
   

    };



    // Function to log out (remove token and user info from localStorage)
    const logout = () => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userInfo");
        setAuthToken(null);
        setUserInfo(null);
        navigate("/login");
    };

    return (
        <AuthContext.Provider value={{ authToken, setAuthToken,userInfo, login,register, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    return useContext(AuthContext);
};
