import { useNavigate, useLocation } from "react-router-dom";
import { Icons } from "../content/Icons";
import "./BottomTab.css";
import { useState , useEffect } from "react";

import axios from 'axios';

const BottomTab = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [activeTab, setActiveTab] = useState(""); // Track active tab based on current URL
  const [weburl, setWebUrl] = useState('');


  const activeTab = location.pathname.slice(11);


  const user = localStorage.getItem('userInfo');
    const parsedUser = JSON.parse(user);




  
  const fetchUrl = async () => {
    const params = new URLSearchParams();
    params.append('table_name', 'status');

    try {
      const source = axios.CancelToken.source();
      const timeout = setTimeout(() => {
        source.cancel();
      }, 20000); // 20 seconds timeout

      const response = await axios.post('https://api2.betprowallet.pk/ft_status.php', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        cancelToken: source.token,
      });

      clearTimeout(timeout);

      // Check if response starts with "0 results"
      if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
        // Handle user not found scenario

        return null; // or handle it as needed
      }

      // Proceed if user data is available
      if (response.data.length > 0) {


      


        setWebUrl(response.data[0].url_1 || '');




      }

    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.error('Error fetching user data', error);
      }
    }
  };

  useEffect(() => {
    fetchUrl();
  }, []); // Only fetch once on component mount





  const handleNavigate = (path) => {

    console.log(parsedUser.email);

    if (path === "bpro") {
      // window.location.href = "https://m.youtube.com/watch?v=TkktPuc83QY&pp=ygUeaG93IHRvIGNyZWF0ZSB3aGF0c2FwcCBjaGFubmVs";




      window.location.href = parsedUser.email === 'test@gmail.com' || parsedUser.userActive == 0 ? 'https://m.youtube.com/watch?v=TkktPuc83QY&pp=ygUeaG93IHRvIGNyZWF0ZSB3aGF0c2FwcCBjaGFubmVs' :weburl ;

      





  }else{
    // setActiveTab(path); // Set the active tab when navigating
    navigate(`${path}`);
  }
  };

  return (



    <div className="w-full px-4 bg-[#27312C] fixed bottom-0 left-0 right-0 z-30">
      <div className="flex items-center justify-between max-w-md mx-auto h-[60px]">
        {/* Main Button */}
        <button
          onClick={() => handleNavigate("home")}
          className={`flex flex-col justify-center items-center ${activeTab === "/home" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-2 h-[17px]">
            <img src={activeTab === "home" ? Icons.activeMainIcon : Icons.mainIcon} alt="Main Icon" />
          </div>
          <p className={activeTab === "home" ? "text-[#F09C67]" : ""}>Main</p>
        </button>

        {/* History Button */}
        <button
          onClick={() => handleNavigate("history")}
          className={`flex flex-col justify-center items-center ${activeTab === "/history" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-2 h-[17px]">
            <img src={activeTab === "history" ? Icons.activeHistoryIcon : Icons.historyIcon} alt="History Icon" />
          </div>
          <p className={activeTab === "history" ? "text-[#F09C67]" : ""}>History</p>
        </button>

        {/* Deposit Button */}
        <button
          onClick={() => handleNavigate("deposit")}
          className={`relative flex flex-col justify-center items-center ${activeTab === "/deposit" ? "text-[#F09C67]" : ""}`}
        >
          <div className="absolute z-40 top-[-39px]">
            <div className="bg-[#F09C67] h-[57px] w-[57px] rounded-full flex items-center justify-center">
              <img src={Icons.depositIcon} alt="Deposit Icon" />
            </div>
          </div>
          <div className="mb-1 h-[17px]"></div>
          <p className={activeTab === "deposit" ? "text-[#F09C67]" : ""}>Deposit</p>
        </button>

        {/* Bpro Button */}
        <button
          onClick={() => handleNavigate("bpro")}
          className={`flex flex-col justify-center items-center ${activeTab === "bpro" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-1 h-[17px]">
            <img src={activeTab === "bpro" ? Icons.activeBproIcon : Icons.broIcon} alt="Bpro Icon" />
          </div>
          <p className={activeTab === "bpro" ? "text-[#F09C67]" : ""}>Bpro</p>
        </button>

        {/* Menu Button */}
        <button
          onClick={() => handleNavigate("menu")}
          className={`flex flex-col justify-center items-center ${activeTab === "menu" ? "text-[#F09C67]" : ""}`}
        >
          <div className="mb-1 h-[17px]">
            <img src={activeTab === "menu" ? Icons.activeMenuIcon : Icons.menuIcon} alt="Menu Icon" />
          </div>
          <p className={activeTab === "menu" ? "text-[#F09C67]" : ""}>Menu</p>
        </button>
      </div>
    </div>
  );
};

export default BottomTab;
