import React, {useState , useEffect} from 'react'
import "./Home.css"
import { useFormik } from 'formik';
import * as Yup from "yup";
import { Icons } from '../../../content/Icons';
import Header from '../../../components/Header';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../../context/AuthContext';

import axios from 'axios';
const Home = () => {

    const navigate = useNavigate();

    const {logout} =useAuth()


    const user = localStorage.getItem('userInfo');
    const parsedUser = JSON.parse(user);
    const [whatsappNumber, setWhatsappNumber] = useState('');

    const [MsgOfAlert , setMsgOfAlert]  = useState('');
    const [isAlertMsg , setAlertMsg] = useState(false);

    const [weburl, setWebUrl] = useState('');


    const fetchwhatsappnum = async () => {
        const params = new URLSearchParams();
        params.append('table_name', 'status');
    
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 20000); // 20 seconds timeout
    
          const response = await axios.post('https://api2.betprowallet.pk/ft_status.php', params, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: source.token,
          });
    
          clearTimeout(timeout);
    
          // Check if response starts with "0 results"
          if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
            // Handle user not found scenario
    
            return null; // or handle it as needed
          }
    
          // Proceed if user data is available
          if (response.data.length > 0) {
    
    
            if (response.data[0].status_on === "1") {
    
              setWhatsappNumber(response.data[0].status_msg);
    
            } else {
              setWhatsappNumber('');
            }


            setWebUrl(response.data[0].url || '');



    
          }
    
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.error('Error fetching user data', error);
          }
        }
      };
    
    
      

      useEffect(() => {
        fetchwhatsappnum();
      }, []); // Only fetch once on component mount
    
    
      const fetchUserData = async () => {
        const params = new URLSearchParams();
        params.append('username', parsedUser.email);
        params.append('fieldName', 'user_email');
        params.append('table_name', 'users');
    
        try {
          const source = axios.CancelToken.source();
          const timeout = setTimeout(() => {
            source.cancel();
          }, 20000); // 20 seconds timeout
    
          const response = await axios.post('https://api2.betprowallet.pk/loginUserApi_ft.php', params, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            cancelToken: source.token,
          });
    
          clearTimeout(timeout);
    
          // Check if response starts with "0 results"
          if (response.data && typeof response.data === 'string' && response.data.startsWith('0 results')) {
            // Handle user not found scenario
    
            return null; // or handle it as needed
          }
    
          // Proceed if user data is available
          if (response.data.length > 0) {

            if (response.data[0].user_blocked === '0') {

            const newUser = {
              name: response.data[0]?.user_fullname || 'User',
              bp_username: response.data[0]?.user_betpro_username || '',
              bp_pass: response.data[0]?.user_betpro_password || '',
              email: response.data[0]?.user_email || '',
              password: '',
              number: response.data[0]?.user_whatsapp || 'N/A',
              userID: response.data[0]?.user_id || 'N/A',
              userActive: response.data[0]?.user_active || 0,
            };
    
            const existingUser = JSON.parse(localStorage.getItem('userInfo'));
    
            // Compare new user data with existing user data
            if (JSON.stringify(existingUser) !== JSON.stringify(newUser)) {
              // Update local storage
              localStorage.setItem('userInfo', JSON.stringify(newUser));
              // Refresh the page
              window.location.reload();
            }
          } else {

        logout();

          }
        }
    
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Request canceled', error.message);
          } else {
            console.error('Error fetching user data', error);
          }
        }
      };
    
      // Call fetchUserData periodically
      const startUserListener = () => {
        fetchUserData(); // Initial fetch
        const intervalId = setInterval(fetchUserData, 5000); // Check every 30 seconds
    
        // Cleanup on component unmount or when you no longer need the listener
        return () => clearInterval(intervalId);
      };
    
     
     

      useEffect(() => {
        // Use this function to start the listener when needed (e.g., on component mount)
      startUserListener();
      }, []); // Only fetch once on component mount
    
    



      const handlewhatsappclick = () => {


        if (whatsappNumber.trim() !== '') {
          const formattedNumber = whatsappNumber.replace(/\D/g, ''); // Remove any non-numeric characters
          const whatsappLink = `https://wa.me/${formattedNumber}`; // Create the WhatsApp link
    
          // Redirect to the WhatsApp link
          window.open(whatsappLink, '_blank');
        } else {
          console.log('WhatsApp number is empty');
          // Optionally, you can show an error message to the user here
        }
    
      }
    




    /////////////////////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////



    const validationSchema = Yup.object({
        betproId: Yup.string()
            .required("Please provide a Betpro Id"),
        password: Yup.string()
            .min(8, "Password must be at least 8 characters long")
            .required("Password is required"),
    });

    // Formik setup
    const formik = useFormik({


        initialValues: {
            betproId: parsedUser.bp_username || "",
            password: parsedUser.bp_pass || "",
        },
        
        validationSchema,
        onSubmit: (values) => {
            console.log("Form Submitted:", values);
        },
    });

    const copyToClipboard1 = () => {
        navigator.clipboard.writeText(formik.values.betproId)
            .then(() => {

                // alert("Betpro ID copied to clipboard!");

                
      setMsgOfAlert('Betpro ID copied to clipboard!');
      setAlertMsg(true);

            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    const copyToClipboard2 = () => {
        navigator.clipboard.writeText(formik.values.password)
            .then(() => {

                // alert("Betpro Password copied to clipboard!");

                setMsgOfAlert('Betpro Password copied to clipboard!');
                setAlertMsg(true);

            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };


    const handleSuccessBtnOk = () => {

        setAlertMsg(false);
      
    
    }

    return (
        <>
            <Header title="Betpro Wallet" />
            <div className='w-full max-w-md mx-auto home-area px-3  relative'>

                {parsedUser.userActive == 0 ? (
                    <div className="top-bar pt-5">
                        <h1>Betpro Account is not activated</h1>
                    </div>

                ) : (
                    <div className="top-bar pt-5">
                        <h1>Betpro Account is activated</h1>
                    </div>
                )

                }

                <form
                    onSubmit={formik.handleSubmit}
                    className="home-form mt-4 w-full mb-10"
                >
                    <div>
                        {/* Betpro ID:
                        <div className="form-group">
                            <div className="relative flex items-center">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.userIcon} alt="userIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Betpro ID:</p>
                                </div>
                                <input
                                    className={`form-control ${formik.touched.betproId && formik.errors.betproId
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type="text"
                                    name="betproId"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.betproId}
                                    disabled
                                />
                                {parsedUser.userActive == 1 ? (
                                    <button type="button" onClick={copyToClipboard1} className="ml-2">
                                        <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        
                        <div className="divider"></div>

                        <div className="form-group">
                            <div className="relative flex items-center">
                                <div className="flex items-center h-full w-[110px] justify-between pl-2">
                                    <img src={Icons.lockIcon} alt="userIcon" />
                                    <p className=" font-['Inter',sans-serif] text-[#897E7E] font-[400] text-[12px]">Password:</p>
                                </div>
                                <input
                                    className={`form-control ${formik.touched.password && formik.errors.password
                                        ? "border-red-500"
                                        : "border-gray-300"
                                        }`}
                                    type={"text"}
                                    name="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                    disabled
                                />
                                {parsedUser.userActive == 1 ? (
                                    <button type="button" onClick={copyToClipboard2} className="ml-2">
                                        <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                                    </button>
                                ) : null}

                            </div>
                        </div> */}


<div className="card-container bg-white rounded-lg shadow-lg p-4">
    {/* Betpro ID */}
    <div className="flex items-center justify-between border-b border-gray-200 py-2">
        <div className="flex items-center">
            <img src={Icons.userIcon} alt="User Icon" className="w-5 h-5 mr-2" />
            <span className="text-gray-600 font-medium text-sm">Betpro ID:</span>
        </div>
        <div className="flex items-center">
            <span className="text-gray-800 text-sm font-medium">{ parsedUser.userActive == 0 ? '': formik.values.betproId }</span>
            {parsedUser.userActive == 1 && (
                <button type="button" onClick={copyToClipboard1} className="ml-3">
                    <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                </button>
            )}
        </div>
    </div>

    {/* Password */}
    <div className="flex items-center justify-between py-2">
        <div className="flex items-center">
            <img src={Icons.lockIcon} alt="Lock Icon" className="w-5 h-5 mr-2" />
            <span className="text-gray-600 font-medium text-sm">Password:</span>
        </div>
        <div className="flex items-center">
            <span className="text-gray-800 text-sm font-medium">{ parsedUser.userActive == 0 ? '': formik.values.password }</span>
            {parsedUser.userActive == 1 && (
                <button type="button" onClick={copyToClipboard2} className="ml-3">
                    <img src={Icons.copyIcon} alt="Copy Icon" className="w-5 h-5 cursor-pointer" />
                </button>
            )}
        </div>
    </div>
</div>


                        <div className="flex items-center justify-between px-[10px] pt-2 pb-2">
                            <div className="error-txt">
                                {formik.touched.betproId && formik.errors.betproId ? (
                                    <p color="danger invalid-txt">
                                        {formik.errors.betproId}
                                    </p>
                                ) : null}
                                {formik.touched.password && formik.errors.password ? (
                                    <p color="danger invalid-txt">
                                        {formik.errors.password}
                                    </p>
                                ) : null}
                            </div>
                        </div>

                        {parsedUser.userActive == 0 ? (

                            <div className="flex">
                                <button type="button" className='activated-btn' onClick={()=>{navigate('/dashboard/deposit');}}>Activate account</button>
                            </div>



                        ) : null}



                    </div>
                </form>

                {parsedUser.userActive == 0 ? (

                <div className="user-instructions">
                    <h2 className='user-title pb-4'>New user instructions</h2>
                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon1} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[12px]' style={{ lineHeight: '12.1px' }}>1st deposit karne per apka Betpro account active hojae ga or ID & password uper show hojae ge.</p>
                        </div>
                    </div>
                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon2} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[12px]' style={{ lineHeight: '12.1px' }}>Deposit ki gayi raqam apke Betpro account me add hojae gi.</p>
                        </div>
                    </div>

                    <div className="flex items-center pb-3">
                        <div className='pr-2'>
                            <img src={Icons.icon3} alt="infoIcon" className='w-[21.5px] h-[21.5px] max-w-[21.5px]' />
                        </div>
                        <div>
                            <p className='text-[#27312C] font-[400] text-[12px]' style={{ lineHeight: '12.1px' }}>Deposit karne ke 10 minutes baad apki id activate ho jaye gi.</p>
                        </div>
                    </div>
                </div>
 ) :(
    <div className="user-instructions">
    <h2 className='user-title pb-4'>Instructions</h2>
    <ol className="list-decimal pl-5 text-[#27312C] font-[400] text-[12px]" style={{ lineHeight: '12.1px' }}>
        <li className="pb-3">Deposit karne ke baad 10 minutes ke ander payment proof send karen.</li>
        <li className="pb-3">Wallet me diye gaye numbers ko save karne ya un pr call karne se apki raqam zaya ho sakti hai.</li>
        <li className="pb-3">Fake payment screenshot bhejne se apka betpro account Ban hojae ga.</li>
    </ol>
</div>

 )}
              
                <div className=" absolute bottom-[10%] right-5">
                    <button type="button" onClick={handlewhatsappclick}>
                        <img src={Icons.logos_whatsapp} alt="logos_whatsapp" className=' w-[45.37px] h-[45.37px]' />
                    </button>
                </div>
            </div>

            {isAlertMsg && (
                    <div className="fixed top-0 left-0 w-full h-[100vh] flex items-center justify-center">
                        <div className="modal">
                            <div className="payment-success flex flex-col justify-between h-full items-center">
                                <img src={Icons.copyclip} alt="SuccessIcon" />
                                <h3>{MsgOfAlert}</h3>
                                <button onClick={handleSuccessBtnOk}>OK</button>
                            </div>
                        </div>
                    </div>
                )}


        </>
    )
}

export default Home
